<template>
    <div class="print-d-block tw-flex tw-flex-col tw-space-y-4 tw-overflow-y-auto tw-px-2 tw-overflow-x-hidden">
        <div class="tw-mb-2">
            <div class="tw-mb-2 tw-flex tw-space-x-2">
                <div class="print-py-0 tw-w-full tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2" identifier="['data']['valid_until']">
                            {{ get(adverseCard, "valid_until", "-") }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['jurisdiction_description']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">REGISTERED IN</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "jurisdiction_description", "-") }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['countries']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">LINKED COUNTRIES</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "countries", "-") }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['service_provider']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">AGENT</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "service_provider", "-") }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['incorporation_date']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Incorporation Date</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "incorporation_date", "-") }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['closed_date']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Closed Date</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "closed_date", "-") }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['inactivation_date']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Inactivation Date</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "inactivation_date", "-") }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['struck_off_date']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Struck Off Date</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "struck_off_date", "-") }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div @click="handleExpandAll" class="print-hidden tw-text-blue-600 tw-ml-auto tw-cursor-pointer hover:tw-text-blue-400 tw-inline-flex">
            <template v-if="!adverseCard.expandedPanel">Expand All</template>
            <template v-else>Collapse All</template>
        </div>

        <!-- Reported Links -->
        <div class="tw-mb-2">
            <template v-if="adverseCard.nodeTypes && adverseCard.nodeTypes.length > 0">
                <div ref="refNodes" @click="fetchMoreInfo(adverseCard, 'nodes')" class="headingBlock print-d-block tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                    <div class="tw-text-lg tw-font-medium">CONNECTIONS</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.nodesExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="tw-p-2 headingBlock-sibling" v-if="adverseCard.nodesExpanded">
                    <div v-for="(nodeData, nodeType) in adverseCard.nodes" :key="nodeType">
                        <template v-if="nodeType !== 'undefined'">
                            <div class="tw-text-lg tw-font-medium tw-capitalize">{{ nodeType }}</div>
                            <div class="tw-w-full lg:tw-h-3/4">
                                <div class="print-overflow-table tw-flex tw-justify-between tw-items-center tw-overflow-x-auto scroll nodes">
                                    <!-- <span > -->
                                    <table class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-border tw-border-solid tw-border-gray-300 tw-mb-2">
                                        <thead class="tw-bg-gray-50">
                                            <tr>
                                                <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                    Name
                                                </th>
                                                <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" v-if="nodeType === 'officer' || nodeType === 'entity'">
                                                    Role
                                                </th>
                                                <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" v-if="nodeType === 'officer' || nodeType === 'entity'">
                                                    From
                                                </th>
                                                <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" v-if="nodeType === 'officer' || nodeType === 'entity'">
                                                    To
                                                </th>
                                                <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" v-if="nodeType === 'entity'">
                                                    Incorporation
                                                </th>
                                                <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" v-if="nodeType === 'entity'">
                                                    Jurisdiction
                                                </th>
                                                <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" v-if="nodeType === 'intermediary' || nodeType === 'entity'">
                                                    Status
                                                </th>
                                                <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                                    Data From
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
                                            <tr v-for="(tableRow, index) in nodeData" :key="index">
                                                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-inline-flex">
                                                    <span v-if="tableRow.doc && tableRow.doc.name" :identifier="`['details'][${tableRow.index}]['doc']['name']`">
                                                        {{ get(tableRow, "doc.name", "") }}
                                                    </span>
                                                    <span v-else-if="tableRow.doc && tableRow.doc.address" :identifier="`['details'][${tableRow.index}]['doc']['address']`">
                                                        {{ get(tableRow, "doc.address", "") }}
                                                    </span>
                                                    <span v-else> - </span>
                                                    <neo-analyse class="tw-ml-2" :source="getSource(nodeType)" :value="tableRow.doc.name" v-if="nodeType !== 'address'"></neo-analyse>
                                                </td>
                                                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-capitalize" v-if="nodeType === 'officer' || nodeType === 'entity'">
                                                    <span v-if="get(tableRow, 'link')" identifier="`['details']['link']`">
                                                        {{ getRole(get(tableRow, "link")) }}
                                                    </span>
                                                    <span v-else-if="get(tableRow, 'TYPE')" :identifier="`['details'][${tableRow.index}]['TYPE']`">
                                                        {{ getRole(get(tableRow, "TYPE").replaceAll("_", " ")) }}
                                                    </span>
                                                    <span v-else> </span>
                                                </td>
                                                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap" :identifier="`['details'][${tableRow.index}]['start_date']`" v-if="nodeType === 'officer' || nodeType === 'entity'">
                                                    {{ get(tableRow, "start_date", "") }}
                                                </td>
                                                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap" :identifier="`['details'][${tableRow.index}]['end_date']`" v-if="nodeType === 'officer' || nodeType === 'entity'">
                                                    {{ get(tableRow, "end_date", "") }}
                                                </td>
                                                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap" :identifier="`['details'][${tableRow.index}]['doc']['incorporation_date']`" v-if="nodeType === 'entity'">
                                                    {{ get(tableRow, "doc.incorporation_date", "") }}
                                                </td>
                                                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap" :identifier="`['details'][${tableRow.index}]['doc']['jurisdiction_description']`" v-if="nodeType === 'entity'">
                                                    {{ get(tableRow, "doc.jurisdiction_description", "") }}
                                                </td>
                                                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap" :identifier="`['details'][${tableRow.index}]['doc']['status']`" v-if="nodeType === 'intermediary' || nodeType === 'entity'">
                                                    {{ get(tableRow, "doc.status", "") }}
                                                </td>
                                                <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                                                    <span v-if="tableRow.doc && tableRow.doc.sourceID" :identifier="`['details'][${tableRow.index}]['doc']['sourceID']`">{{ get(tableRow, "doc.sourceID", "").split("-")[0] }}</span>
                                                    <span v-else :identifier="`['details'][${tableRow.index}]['doc']['sourceID']`">{{ get(tableRow, "sourceID", "").split("-")[0] }} </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- </span> -->
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="tw-py-1 tw-px-2 tw-bg-gray-50 tw-mt-4 tw-border tw-border-solid tw-border-gray-200 tw-rounded">
                    <div class="tw-text-lg tw-font-medium">CONNECTIONS</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.nodesExpanded"></div>
            </template>
        </div>
    </div>
</template>
<script>
import {DateTime} from "luxon";
import {get} from "lodash";
import neoAnalyse from "@/components/analyse";
import printDataMixin from "@/mixins/printDataMixin";
import adverseMixin from "@/mixins/adverseMixin";
import highlighterMixin from "@/mixins/highlighterMixin";
import NeoLoader from "@/components/loader";
export default {
    components: {neoAnalyse},
    data() {
        return {
            urlFields: ["related url", "locationurl"],
            isPrintLoader: false,
            refBtnClick: {
                refNodes: true,
            },
        };
    },
    props: {
        expandedCard: {
            type: Object,
        },
        isResultExpanded: {
            type: Boolean,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [adverseMixin, printDataMixin, highlighterMixin],
    computed: {
        adverseCardKey() {
            return this.expandedCard?.key;
        },
        adverseCard() {
            return this.expandedCard?.api_all_data ?? {};
        },
    },
    watch: {},
    mounted() {
        this.highlightChanges();
    },
    methods: {
        get,
        async printPageIcij(id, name, noPrint) {
            this.isPrintLoader = true;
            await this.handleExpandAll(true);
            const htmlPDF = this.printData(id, name, noPrint);
            this.$emit("updatePrintStatus", "");
            this.isPrintLoader = false;
            if (noPrint) {
                return htmlPDF;
            }
        },
        getSource(nodeType) {
            switch (nodeType) {
                case "officer":
                    return "name";
                case "intermediary":
                    return "company_name";
                case "entity":
                    return "company_name";
                case "other":
                    return "company_name";
                default:
                    return "name";
            }
        },

        getRole(value) {
            return value
                ?.split(" of")
                .filter((el) => el)
                .join("of");
        },
    },
};
</script>

<style src="./../monitoring-differential-highlighter/monitoring-differential-highlighter.scss" scoped lang="scss"></style>
<style lang="scss" scoped>
@media print {
    .tw-rotate-180 {
        display: none !important;
    }
    .normal-print-table tr {
        page-break-inside: avoid !important;
    }
    /* .tw-mb-2 {
            page-break-inside: auto !important;
            page-break-after: auto !important;
            page-break-before: auto !important;
            box-sizing: content-box !important;
    } */
}
@media print {
    .searchIcon {
        display: none !important;
    }
    .print-d-block {
        display: block !important;
    }
    .print-py-0 {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .print-line-height {
        line-height: 2 !important;
    }
    a {
        display: block !important;
    }
}
.scroll {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
</style>
