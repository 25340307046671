import {EventBus} from "@/main.js";
import {get} from "lodash";
import {DateTime} from "luxon";
import NlpAnalysisHighlighting from "@/components/nlp-analysis-highlighting";
import printDataMixin from "@/mixins/printDataMixin";
import highlighterMixin from "@/mixins/highlighterMixin";
import NeoLoader from "@/components/loader";

export default {
    name: "news-expanded",
    components: {
        "nlp-analysis-highlighting": NlpAnalysisHighlighting,
        NeoLoader,
    },
    props: {
        content: {
            type: Object,
            default: {},
        },
        expandedLoading: {
            type: Boolean,
            default: false,
        },
        allLanguages: {
            type: Array,
            default: () => [],
        },
        isSavedLoading: {
            type: Object,
            default: {},
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMonitoringTab: false,
            isPrintLoader: false,
            fullview: false,
            tabs: [
                {
                    name: "Current",
                    value: "current",
                },
                {
                    name: "Old",
                    value: "old",
                },
            ],
        };
    },
    mixins: [printDataMixin, highlighterMixin],
    watch: {
        content: function (newContent, oldContent) {
            if (this.$refs != null && this.$refs.nlpAnalysisHighlighting != null) {
                this.$refs.nlpAnalysisHighlighting.highlightAll();
            }
            setTimeout(() => {
                this.highlightChanges();
            }, 100);
        },
    },
    mounted() {
        this.isMonitoringTab = this.$route.name === "monitoring";
        if (this.isComparisonModeOn) {
            setTimeout(async () => {
                this.content.detailsExpanded = false;
                if (this.content.key === "dowJonesFactiva")
                    await EventBus.$emit("openShowMorePanel", {
                        result: this.content,
                        isOldVersion: !this.content.is_parent,
                    });
                this.highlightChanges();
            }, 100);
        }
    },
    computed: {
        getSavedClasses() {
            let classes = "";
            if (this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex === this.content.api_all_data && this.content.api_all_data.doc_id) classes += " tw-opacity-40 tw-cursor-not-allowed ";
            if (this.content.api_all_data?.saved) classes += " tw-text-blue-700 tw-font-semibold  tw-text-xs tw-bg-blue-200 ";
            else classes += " tw-bg-blue-200 tw-text-blue-800 ";
            return classes;
        },
        selectedTab() {
            return this.isOldVersionSelected ? "old" : "current";
        },
    },
    methods: {
        get,
        getIdentifierMapping(key, bi, length) {
            let mapping = "";
            if (key === "factiva_body") {
                for (let i = 0; i < length; i++) {
                    mapping += `['details']['Body'][${bi}]['Items'][${i}]['Value'] 
                        ['details']['Body'][${bi}]['Items'][${i}]['Text'] 
                        ['details']['Body'][${bi}]['Items'][${i}]['Name'] `;
                }
            }
            return mapping;
        },
        closeMoreInfo() {
            if (this.fullview) {
                this.onFullScreen(true);
            } else {
                EventBus.$emit("closeMoreInfo");
            }
        },
        getFormattedTime(ts, format) {
            let date = DateTime.fromISO(ts);
            return format ? date.toFormat(format) : date.toFormat("EEE, dd MMM yyyy, hh:mm:ss a");
        },
        tailParagraphs(para) {
            try {
                let item = para.map((ele) =>
                    ele.Items.map((el1) => el1.Value || el1.Name)
                        .filter((el2) => el2)
                        .join("")
                );
                return item.join("") ? item.join("") : "-";
            } catch (error) {}
            return "-";
        },
        handleResultSaveData(result) {
            EventBus.$emit("handleNewsResultSave", result);
        },
        getLanguage(code) {
            return this.allLanguages.find((ele) => ele.value == code)?.label ?? code;
        },
        fetchMoreInfo(data, type) {
            data[`${type}Expanded`] = !data[`${type}Expanded`];
            this.$forceUpdate();
        },
        async printDocuments(id, fileName, key, noPrint) {
            this.isPrintLoader = true;
            let htmlPDF = "";
            if (key == "news") {
                htmlPDF = await this.printPage(id, fileName, noPrint);
            } else if (key == "dowJonesFactiva") {
                htmlPDF = await this.printPage(id, fileName, noPrint);
            }
            return htmlPDF;
        },
        async printPage(id, name, print, noPrint) {
            this.printDone = false;
            this.isPrintLoader = true;
            if (!print) {
                let htmlPDF = await this.printData(id, name, noPrint);
                this.$emit("updatePrintStatus", "");
                this.isPrintLoader = false;
                if (noPrint) {
                    return htmlPDF;
                }
            }
        },

        updatePrintStatus() {
            this.isPrintLoader = !this.isPrintLoader;
        },
        getContent(content) {
            let regex = /(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g;
            let contentHTML = "";
            contentHTML = content?.content;
            return contentHTML.replace(regex, " ");
        },
        onFullScreen(reset = false) {
            let elem = document.getElementById("result-expanded");
            if (reset) {
                elem.style.setProperty("width", "60%", "important");
            } else {
                elem.style.width = null;
            }
            if (elem) {
                elem.style.position = reset ? null : "absolute";
                elem.style.inset = reset ? null : "0";
                elem.style.padding = reset ? "0 0.5rem 0.5rem 0.5rem" : 0;
                elem.style.zIndex = reset ? null : "21";
            }

            let resElem = document.getElementById("result-list-wrapper");
            if (resElem) resElem.style.borderRadius = reset ? "0.375rem" : 0;

            // let header = document.getElementById("neo-header");
            // header.style.display = "none"; // flex

            let buttonToolbar = document.getElementById("button-toolbar");
            if (buttonToolbar) buttonToolbar.style.display = reset ? null : "none"; // 25

            let osintExpand = document.getElementById("osint--panel--drawer");
            if (osintExpand) osintExpand.style.display = reset ? null : "none"; //20

            // let versioningTabSwitch = document.getElementById("monitoring-version-switch")
            // if (versioningTabSwitch)
            //     versioningTabSwitch.style.display = reset ? null : "none"; //20

            let monitoringDateRangeSlider = document.getElementsByClassName("monitoring-dateRange-slider");
            if (monitoringDateRangeSlider?.[0]) monitoringDateRangeSlider[0].style.display = reset ? null : "none"; //20

            this.fullview = !reset;
        },
    },
};
