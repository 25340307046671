<template>
    <div class="print-d-block tw-flex tw-flex-col tw-space-y-4 tw-overflow-y-auto tw-px-2 tw-overflow-x-hidden">
        <div class="tw-mb-2 headingBlock-sibling">
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['category']['CATEGORY']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">CATEGORY</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "category.CATEGORY", "-") }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['category']['SUB_CATEGORY']">
                <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">SUB CATEGORY</div>
                <div class="print-py-0 tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <span class="tw-space-y-2">
                            {{ get(adverseCard, "sub_category.SUB_CATEGORY", "-") }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div @click="handleExpandAll" class="print-hidden tw-text-blue-600 tw-ml-auto tw-cursor-pointer hover:tw-text-blue-400 tw-inline-flex">
            <template v-if="!adverseCard.expandedPanel">Expand All</template>
            <template v-else>Collapse All</template>
        </div>
        <div class="tw-mb-2">
            <template v-if="adverseCard.keywords && adverseCard.keywords.length > 0">
                <div ref="refworldcheck" @click="fetchMoreInfo(adverseCard, 'worldCheck')" class="headingBlock print-d-block tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                    <div class="tw-text-lg tw-font-medium">WORLD-CHECK KEYWORD(S)</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.worldCheckExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="tw-p-2 headingBlock-sibling" v-if="adverseCard.worldCheckExpanded">
                    <div class="tw-flex tw-justify-between tw-flex-col">
                        <div class="tw-space-y-2 tw-flex tw-mt-2" v-for="(keyword, index) in adverseCard.keywords" :key="keyword.KEYWORD" :identifier="`['data']['keywords'][${index}]['FULL_NAME_OF_SOURCE']`">
                            {{ get(keyword, "FULL_NAME_OF_SOURCE", "-") }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="tw-py-1 tw-px-2 tw-bg-gray-50 tw-mt-4 tw-border tw-border-solid tw-border-gray-200 tw-rounded">
                    <div class="tw-text-lg tw-font-medium">WORLD-CHECK KEYWORD(S)</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.worldCheckExpanded"></div>
            </template>
        </div>

        <!-- Aliases -->
        <div class="tw-mb-2">
            <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'aliases')" class="headingBlock print-d-block tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                <div class="tw-text-lg tw-font-medium">ALIAS(ES)</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.aliasesExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </div>
            <div class="tw-p-2 headingBlock-sibling" v-if="adverseCard.aliasesExpanded">
                <ul class="tw-mb-4 tw-pl-3 tw-space-y-2">
                    <li v-for="(alias, index) in get(adverseCard, 'aliases', [])" :key="alias" :identifier="`['data']['aliases'][${index}]`">
                        {{ alias }}
                    </li>
                </ul>
                <div class="tw-mt-2">
                    <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['title']">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Title</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center">
                                <span class="tw-space-y-2">
                                    <template>{{ get(adverseCard, "title", "-") }}</template>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['position'][0]">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Position</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center">
                                <span class="tw-space-y-2">
                                    <template>{{ get(adverseCard, "position[0]", "-") }}</template>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['place_of_birth']">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Place of Birth</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center">
                                <span class="tw-space-y-2">
                                    <template>{{ get(adverseCard, "place_of_birth", "-") }}</template>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['dob']">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">DOB</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center">
                                <span class="tw-space-y-2">
                                    <template>{{ get(adverseCard, "dob", "-") }}</template>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['age']">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Age</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center">
                                <!-- <span class="tw-space-y-2">
                                    {{ get(adverseCard, "age", "N/A") }}
                                </span> -->
                                <span class="tw-space-y-2">
                                    <template>{{ get(adverseCard, "age", "-") }}</template>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mb-2 tw-flex tw-space-x-2" identifier="['data']['deceased']">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Deceased</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center">
                                <span class="tw-space-y-2">
                                    <template>{{ get(adverseCard, "deceased", "-") }}</template>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mb-2 tw-flex tw-space-x-2">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Passport(s)</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center">
                                <template v-if="adverseCard.passports">
                                    <span class="tw-space-y-2" v-for="(passport, index) in adverseCard.passports" :key="passport" :identifier="`['data']['passports'][${index}]`">
                                        {{ passport }}
                                    </span>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mb-2 tw-flex tw-space-x-2">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">SSN</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center">
                                <template v-if="adverseCard.ssn">
                                    <span class="tw-space-y-2" v-for="(ssn_id, index) in adverseCard.ssn" :key="ssn_id" :identifier="`['data']['ssn'][${index}]`">
                                        {{ ssn_id }}
                                    </span>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                    </div>
                    <div class="tw-mb-2 tw-flex tw-space-x-2">
                        <div class="tw-w-1/3 lg:tw-w-1/4 tw-font-semibold tw-text-left tw-py-2 tw-pr-3 tw-text-gray-400">Identification Number(s)</div>
                        <div class="tw-w-2/3 lg:tw-h-3/4 tw-pl-3 tw-pr-3 tw-py-2">
                            <div class="tw-flex tw-justify-between tw-items-center tw-flex-wrap">
                                <template v-if="adverseCard.identification_numbers">
                                    <span class="tw-space-y-2" v-for="(id_num, index) in adverseCard.identification_numbers" :key="id_num" :identifier="`['data']['identification_numbers'][${index}]`">
                                        {{ id_num }}
                                    </span>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Citizenships -->
        <div class="tw-mb-2">
            <template v-if="get(adverseCard, 'citizenship', false)">
                <div ref="refCitizenships" @click="fetchMoreInfo(adverseCard, 'citizenships')" class="headingBlock print-d-block tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                    <div class="tw-text-lg tw-font-medium">CITIZENSHIP(S)</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.citizenshipsExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="tw-p-2 headingBlock-sibling" v-if="adverseCard.citizenshipsExpanded" identifier="['data']['citizenship']">
                    {{ get(adverseCard, "citizenship", "-") }}
                </div>
            </template>
            <template v-else>
                <div class="tw-py-1 tw-px-2 tw-bg-gray-50 tw-mt-4 tw-border tw-border-solid tw-border-gray-200 tw-rounded">
                    <div class="tw-text-lg tw-font-medium">CITIZENSHIP(S)</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.citizenshipsExpanded"></div>
            </template>
        </div>

        <!-- Locations -->
        <div class="tw-mb-2">
            <template v-if="adverseCard.locations && adverseCard.locations.length > 0">
                <div ref="refLocations" @click="fetchMoreInfo(adverseCard, 'locations')" class="headingBlock print-d-block tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                    <div class="tw-text-lg tw-font-medium">LOCATION(S)</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.locationsExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="tw-p-2 headingBlock-sibling" v-if="adverseCard.locationsExpanded">
                    <!-- {{ get(adverseCard, "citizenship", "N/A") }} -->
                    <div class="tw-flex tw-justify-between tw-flex-col">
                        <div class="tw-space-y-2 tw-flex tw-mt-2" v-for="(location, index) in adverseCard.locations" :key="location" :identifier="`['data']['locations'][${index}]'`">
                            {{ location }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="tw-py-1 tw-px-2 tw-bg-gray-50 tw-mt-4 tw-border tw-border-solid tw-border-gray-200 tw-rounded">
                    <div class="tw-text-lg tw-font-medium">LOCATION(S)</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.locationsExpanded"></div>
            </template>
        </div>

        <!-- Companies Reported -->
        <div class="tw-mb-2">
            <template v-if="adverseCard.companiesReported && adverseCard.companiesReported.length > 0">
                <div ref="refCompaniesReported" @click="fetchMoreInfo(adverseCard, 'companiesReported')" class="headingBlock tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                    <div class="tw-text-lg tw-font-medium">COMPANY(IES) REPORTED IN SOURCES BELOW</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.companiesReportedExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="tw-p-2 headingBlock-sibling" v-if="adverseCard.companiesReportedExpanded">
                    <div class="tw-w-full lg:tw-h-3/4">
                        <div class="print-overflow-table tw-flex tw-justify-between tw-items-center tw-overflow-x-auto scroll companiesReported">
                            <table class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-border tw-border-solid tw-border-gray-300 tw-mb-2">
                                <thead class="tw-bg-gray-50">
                                    <tr>
                                        <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                            Name
                                        </th>
                                        <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                            Country
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
                                    <tr v-for="(tableRow, index) in adverseCard.companiesReported" :key="index">
                                        <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-inline-flex" :identifier="`['details']['companies']['${tableRow.id}']['name']`">
                                            <span>{{ get(tableRow, "name", "-") }}</span>
                                            <neo-analyse class="tw-ml-2" v-if="get(tableRow, 'name', false)" source="company_name" :value="tableRow.name"></neo-analyse>
                                        </td>
                                        <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap" :identifier="`['details']['companies']['${tableRow.id}']['country']`">
                                            {{ get(tableRow, "country", "-") }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="tw-py-1 tw-px-2 tw-bg-gray-50 tw-mt-4 tw-border tw-border-solid tw-border-gray-200 tw-rounded">
                    <div class="tw-text-lg tw-font-medium">COMPANY(IES) REPORTED IN SOURCES BELOW</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.companiesReportedExpanded"></div>
            </template>
        </div>

        <!-- Reported Links -->
        <div class="tw-mb-2">
            <template v-if="adverseCard.reportedLinked && adverseCard.reportedLinked.length > 0">
                <div ref="refReportedLinked" @click="fetchMoreInfo(adverseCard, 'reportedLinked')" class="headingBlock print-d-block tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                    <div class="tw-text-lg tw-font-medium">REPORTED TO BE LINKED TO</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.reportedLinkedExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="tw-p-2 headingBlock-sibling" v-if="adverseCard.reportedLinkedExpanded">
                    <div class="tw-w-full lg:tw-h-3/4">
                        <div class="print-overflow-table tw-flex tw-justify-between tw-items-center tw-overflow-x-auto scroll reportedLinked">
                            <!-- <span > -->
                            <table class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-border tw-border-solid tw-border-gray-300 tw-mb-2">
                                <thead class="tw-bg-gray-50">
                                    <tr>
                                        <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                            Name
                                        </th>
                                        <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                            Country
                                        </th>
                                        <th scope="col" class="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                                            Date of Birth
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
                                    <tr v-for="(tableRow, index) in adverseCard.reportedLinked" :key="index">
                                        <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-inline-flex" :identifier="`['details']['linked_to']['${tableRow.id}']['name']`">
                                            <span>{{ get(tableRow, "name", "") }}</span>
                                            <neo-analyse class="tw-ml-2" source="name" :value="tableRow.name"></neo-analyse>
                                        </td>
                                        <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap" :identifier="`['details']['linked_to']['${tableRow.id}']['country']`">
                                            {{ get(tableRow, "country", "") }}
                                        </td>
                                        <td class="tw-px-6 tw-py-4 tw-whitespace-nowrap" :identifier="`['details']['linked_to']['${tableRow.id}']['dob']`">
                                            {{ get(tableRow, "dob", "") }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- </span> -->
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="tw-py-1 tw-px-2 tw-bg-gray-50 tw-mt-4 tw-border tw-border-solid tw-border-gray-200 tw-rounded">
                    <div class="tw-text-lg tw-font-medium">REPORTED TO BE LINKED TO</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.reportedLinkedExpanded"></div>
            </template>
        </div>

        <!-- Furher Information -->
        <div class="tw-mb-2" v-if="get(adverseCard, 'further_information', false)">
            <div ref="refFurtherInfo" @click="fetchMoreInfo(adverseCard, 'furtherInformation')" class="headingBlock tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                <div class="tw-text-lg tw-font-medium">THE FOLLOWING INFORMATION WAS REPORTED IN ONE OR MORE OF THE SOURCES BELOW</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.furtherInformationExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </div>
            <div class="tw-p-2 headingBlock-sibling print-line-height tw-whitespace-pre-line" v-if="adverseCard.furtherInformationExpanded">
                <!-- {{ get(adverseCard, "further_information", "-") }} -->
                <p identifier="['data']['further_information']" v-html="getFurtherInformation(adverseCard)"></p>
            </div>
        </div>
        <div v-else class="tw-mb-2">
            <div class="tw-py-1 tw-px-2 tw-bg-gray-50 tw-mt-4 tw-border tw-border-solid tw-border-gray-200 tw-rounded">
                <div class="tw-text-lg tw-font-medium">THE FOLLOWING INFORMATION WAS REPORTED IN ONE OR MORE OF THE SOURCES BELOW</div>
            </div>
            <div class="headingBlock-sibling" v-if="adverseCard.furtherInformationExpanded"></div>
        </div>

        <!-- Information Sources -->
        <div class="tw-mb-2">
            <template v-if="adverseCard.external_sources && adverseCard.external_sources.length > 0">
                <div ref="refInformationSources" @click="fetchMoreInfo(adverseCard, 'informationSources')" class="headingBlock print-d-block tw-flex tw-items-center tw-py-1 tw-px-2 tw-bg-blue-50 tw-mt-4 tw-justify-between tw-border tw-border-solid tw-border-blue-200 tw-rounded tw-cursor-pointer hover:tw-shadow-md">
                    <div class="tw-text-lg tw-font-medium">INFORMATION SOURCES: LINKS</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" :class="{'tw-rotate-180': adverseCard.informationSourcesExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="tw-p-2 headingBlock-sibling" v-if="adverseCard.informationSourcesExpanded">
                    <div class="print-d-block tw-flex tw-justify-between tw-flex-col">
                        <!-- <div class="tw-space-y-2 tw-flex tw-mt-2 tw-flex-wrap">
                            {{ source }}
                        </div> -->
                        <a :href="source" target="_blank" rel="noreferrer" class="tw-no-underline tw-text-blue-600 tw-mt-2 tw-max-w-max tw-break-all" v-for="(source, index) in adverseCard.external_sources" :key="source" :identifier="`['data']['external_sources'][${index}]`">
                            {{ source }}
                        </a>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="tw-text-lg tw-font-medium">INFORMATION SOURCES: LINKS</div>
                <div class="headingBlock-sibling" v-if="adverseCard.informationSourcesExpanded"></div>
            </template>
        </div>
    </div>
</template>
<script>
import {get} from "lodash";
import neoAnalyse from "@/components/analyse";
import printDataMixin from "@/mixins/printDataMixin";
import adverseMixin from "@/mixins/adverseMixin";
import highlighterMixin from "@/mixins/highlighterMixin";

export default {
    components: {neoAnalyse},
    data() {
        return {
            urlFields: ["related url", "locationurl"],
            isPrintLoader: false,
            refBtnClick: {
                advDetails: true,
                advAssociates: true,
                advPEP: true,
                advPepClass: true,
                advFitness: true,
                advImages: true,
                advAdverseMedia: true,
                advMediaData: true,
                refNameSection: true,
                refcontryTerritoryDetails: true,
                refDatesSection: true,
                refAddressSection: true,
                refImagesSection: true,
                refRolesSection: true,
                refRelativeCloseDowjonesSection: true,
                refProfileNotesDowjonesSection: true,
                refSourceSection: true,
                refworldcheck: true,
                refAliases: true,
                refCitizenships: true,
                refLocations: true,
                refFurtherInfo: true,
                refInformationSources: true,
            },
        };
    },
    props: {
        expandedCard: {
            type: Object,
        },
        isResultExpanded: {
            type: Boolean,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [adverseMixin, printDataMixin, highlighterMixin],
    computed: {
        isMedia() {
            return this.adverseCard?.doc.media && this.adverseCard?.doc.media.length > 0;
        },
        adverseCardKey() {
            return this.expandedCard?.key;
        },
        adverseCard() {
            return this.expandedCard?.api_all_data ?? {};
        },
    },
    watch: {},
    mounted() {
        this.highlightChanges();
    },
    methods: {
        get,
        async printPageWorldClock(id, name, noPrint) {
            this.isPrintLoader = true;
            await this.handleExpandAll(true);
            const htmlPDF = await this.printData(id, name, noPrint);
            this.$emit("updatePrintStatus", "");
            this.isPrintLoader = false;
            if (noPrint) {
                return htmlPDF;
            }
        },
        getFurtherInformation(adverse) {
            let string = this.get(adverse, "further_information", "-");
            string = string.replace(/\[.+?\]/g, (str, token) => {
                // matched [] and replaced matched words with bold styled html and removed / sliced away  [ ] square brackets
                return (str = `<span class='tw-font-bold tw-tracking-wider'> ${str.slice(1, -1)} </span>`);
            });
            return string;
        },
    },
};
</script>

<style src="./../monitoring-differential-highlighter/monitoring-differential-highlighter.scss" scoped lang="scss"></style>
<style lang="scss" scoped>
@media print {
    .tw-rotate-180 {
        display: none !important;
    }
    .normal-print-table tr {
        page-break-inside: avoid !important;
    }
    /* .tw-mb-2 {
            page-break-inside: auto !important;
            page-break-after: auto !important;
            page-break-before: auto !important;
            box-sizing: content-box !important;
    } */
}
@media print {
    .searchIcon {
        display: none !important;
    }
    .print-d-block {
        display: block !important;
    }
    .print-py-0 {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .print-line-height {
        line-height: 2 !important;
    }
    a {
        display: block !important;
    }
}
.scroll {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
</style>
